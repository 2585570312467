<template>
  <div class="plo">
    <!-- 头部区 -->
    <div class="headerBox">
      <!-- Logo -->
      <div class="header_img">
        <img style="width: 197px; height: 57px;"  src="@/assets/img/6pllogo.png" />
      </div>
      <!-- 导航区 -->
      <div class="nav">
        <el-menu  mode="horizontal" router background-color="#007BFF"
        active-text-color="#007BFF"  text-color="#fff" :default-active="activePath"  >
          <el-menu-item index="/Page" @click="saveNayState('/' + 'Page' )">{{ td("首页") }}</el-menu-item>
          <!-- <el-menu-item index="/ERPP" @click="saveNayState('/' + 'ERPP' )">{{ td("ERP业务") }}</el-menu-item> -->
          <el-menu-item index="/WMS" @click="saveNayState('/' + 'WMS' )">{{ td("云仓业务") }}</el-menu-item>
          <el-menu-item index="/TMS" @click="saveNayState('/' + 'TMS' )">{{ td("集运业务") }}</el-menu-item>
        </el-menu>
      </div>
      <!-- 个人 -->
      <div class="box">
        <div class="lang">
          <i class="el-icon-orange" style="transform: translate(15px,2px);color:#fff;margin-right: 10px;" />
          <el-select style="width:120px" v-model="langName" @change="select_language(langName)" size="mini"  >
            <el-option style="font-color:#707070;"  v-for="state in StatusOptions" :key="state.langCode" :value="state.langCode" :label="state.langName" />
          </el-select>
        </div>


        <div class="avatar-container">
          <img :src="avatar" class="user-avatar">
        </div>


        <el-dropdown class="userName" trigger="click">
           <span style="color:#fff">{{  this.$store.state.user.cuscode}}</span>
           <i class="el-icon-caret-bottom" />
          <el-dropdown-menu slot="dropdown">
            <router-link to="/user">
              <el-dropdown-item>{{ td("个人中心") }}</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="logout">
              <span>{{ td("退出登录") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- <div class="userName">
          <span style="color:#fff">{{  this.$store.state.user.name}}</span>
          <i class="el-icon-caret-bottom" />
        </div> -->

      </div>


    </div>

      <router-view @setlocation="setVal"></router-view>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../store/index.js'
import Cookies from "js-cookie"
import {GetCategoryLangByLangCode,GetCaptionLangByLangCode} from "@/api/system/actions"
import { getLangCodeOption } from "@/api/system/captionLang";

export default {
  name: "erp",
  data() {
    return {
      StatusOptions:[],
      langName: null,
      //被激活的链接地址
      activePath: "",
    }
  },
  created() {
  	this.getDefaultLanguage()
    this.activePath = window.sessionStorage.getItem("activePath");
  },
  computed: {
    ...mapGetters([ 'avatar']),
  },
  methods:{
    async logout() {
      this.$confirm( this.td('确定注销并退出系统吗？'), this.td('提示'), {
        confirmButtonText: this.td('确定'),
        cancelButtonText: this.td('取消'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          //国际化需要！退出登录时，设置其cookies为null或者默认值
          Cookies.set("langId","1")
          location.href = '/index';
          window.sessionStorage.setItem("activePath", '/Page')
        })
      }).catch(() => {});
    },
	setVal(val){
		this.activePath = window.sessionStorage.getItem("activePath");
	},
    select_language(value){
      Cookies.set("langId",value)
	  location.reload()
      store.dispatch(GetCategoryLangByLangCode,value)
      store.dispatch(GetCaptionLangByLangCode,value)
    },
    getDefaultLanguage(){//获取语言的方法
      getLangCodeOption().then(response => {
        this.StatusOptions = response.data;
        // this.langName=this.StatusOptions[0].langCode;
      })
      this.langName = Cookies.get("langId")
    },

    saveNayState(activePath) {
      console.log(activePath,'activePath');
	  Cookies.remove("action");
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  }
};
</script>

<style scoped>
.headerBox {
  position: relative;
  height: 64px;
  background-color: #007BFF;
  font-family: PingFangSC-Regular, PingFang SC;
}
.headerBox .header_img {
  position: absolute;
  top: 4px;
  left: 60px;
}
.headerBox .nav {
  position: absolute;
  bottom: 0;
  left: 256px;
  height: 60px;
  /* width: 100%; */
}
/* 激活 */
.el-menu-item.is-active {
  background-color: #fff !important;
}
/* 左右边距 */
.el-menu--horizontal>.el-menu-item {
  padding: 0 16px;
  box-sizing: border-box;
}

.el-menu-item {
  height: 60px;
  /* width: 88px; */
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
}

::v-deep  .el-menu--horizontal > .el-menu-item {
  border-bottom: none;
  text-decoration: none;
}
::v-deep .el-menu.el-menu--horizontal{
  border: none;
}

.box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
}


.user-avatar {
  /* width: 30px; */
  width: auto;
  max-width: 30px;
  height: 30px;
  border-radius: 50%;
}
.el-icon-caret-bottom {
  font-size: 14px;
}
.userName {
  cursor: pointer;
  color: #fff;
  margin: 0 24px 0 8px;
}


.lang  .el-select {
  /* width: 90px!important; */
}


  .lang  ::v-deep  .el-select  input{
      font-size: 12px;
      border: none;
      background: none;
      text-align: center;
      color: #fff;

  }
  .lang ::v-deep  .el-input__suffix i{
    color: #fff;
    font-weight: bold;

  }
  /* WebKit browsers */
  .lang ::v-deep ::-webkit-input-placeholder {

      font-weight: bold;

  }

  .lang  ::v-deep .el-input__inner {
    width: 80px;
    padding: 0;
  }

  .lang ::v-deep .el-input__suffix {
    right: 16px;

  }

</style>
