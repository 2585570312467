import request from '@/utils/request'
import { getToken } from "@/utils/auth"

//查询所有语种
export function getLangCodeOption() {
  return request({
    url: '/system/captionLang/getLangCodeOption',
    method: 'get',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
  })
}

// 查询页面国际化多语言列表
export function listLang(query) {
  return request({
    url: '/system/captionLang/list',
    method: 'get',
    params: query,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
  })
}

// 查询页面国际化多语言详细
export function getLang(langId) {
  return request({
    url: '/system/captionLang/' + langId,
    method: 'get',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
  })
}

// 新增页面国际化多语言
export function addLang(data) {
  return request({
    url: '/system/captionLang',
    method: 'post',
    data: data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
  })
}

// 修改页面国际化多语言
export function updateLang(data) {
  return request({
    url: '/system/captionLang',
    method: 'put',
    data: data,
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
  })
}

// 删除页面国际化多语言
export function delLang(langId) {
  return request({
    url: '/system/captionLang/' + langId,
    method: 'delete',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
  })
}
